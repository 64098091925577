
import './index.scss';

import "intl-tel-input";
// import Swal from 'sweetalert2';
const Swal = require('sweetalert2')

window.onscroll = function() {myFunction()};

var header = document.getElementById("static-header");
const remToPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
 
var sticky = header.offsetTop;

function myFunction() {
    if (window.pageYOffset > sticky && window.pageYOffset > remToPixels * 2) {
        document.getElementById('logo-getcashi').src = '';
        document.getElementById('logo-getcashi').src = window.location.protocol + "//" + window.location.host + "/assets/images/cashi-logo2.png";
        header.classList.add("sticky");
    } else {
        document.getElementById('logo-getcashi').src = '';
        document.getElementById('logo-getcashi').src = window.location.protocol + "//" + window.location.host + '/assets/images/cashi-logo.png';
        header.classList.remove("sticky");
    }
}
const menuIcon = document.getElementById('menu-icon');
const navLinks = document.getElementById('nav-links');

menuIcon.addEventListener('click', () => {
  navLinks.classList.toggle('active');
});
// document.body.addEventListener("click", function (evt) { 
//     if (navLinks.classList.contains('active')) {
//         navLinks.classList.toggle('active');
//     }
// });


document.addEventListener("DOMContentLoaded", function() {
    var triggers = document.querySelectorAll("[data-trigger]");
    triggers.forEach(function(trigger) {
        trigger.addEventListener("click", function(e) {
            e.preventDefault();
            e.stopPropagation();
            var offcanvas_id = this.getAttribute("data-trigger");
            document.querySelector(offcanvas_id).classList.toggle("show");
            document.body.classList.toggle("offcanvas-active");
            document.querySelector(".screen-overlay").classList.toggle("show");
        });
    });

    // Close menu when pressing ESC
    document.addEventListener("keydown", function(event) {
        if (event.keyCode === 27) {
            document.querySelector(".mobile-offcanvas").classList.remove("show");
            document.body.classList.remove("overlay-active");
        }
    });
});


(() => {
    'use strict'

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.from(forms).forEach(form => {
        form.addEventListener('submit', event => {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
            }
            form.classList.add('was-validated')
        }, false)
    })
})()


const input = document.querySelector("#phone");
const output = document.querySelector("#output");
if(input){
const iti = window.intlTelInput(input, {
    initialCountry: "sd",
    nationalMode: true,
    utilsScript: "../assets/js/utils.js",
    numberType: "MOBILE",
    defaultCountry: "sd",
    geoIpLookup: function (callback) {
        var date = new Date().getTime();
        $.get( "/getipinfo?time=" + date, function () {}).always(
            function (resp) {
                var countryCode = resp && resp.country ? resp.country : "sd";
                callback(countryCode);
            }
        );
    },
    autoHideDialCode: true,
});

var checkForm = false;
const handleChange = () => {
    if (!input.value.trim()) {
        $("#phone").removeClass("valid")
        $("#phone").addClass("notvalid");
        checkForm =false;
    } else if (iti.isValidNumberPrecise()) {
        $("#phone").removeClass("notvalid")
        $("#phone").addClass("valid")
        checkForm =true;
    } else {
        $("#phone").removeClass("valid")
        $("#phone").addClass("notvalid");
        checkForm =false;
    }
};

// listen to "keyup", but also "change" to update when the user selects a country
input.addEventListener('change', handleChange);
input.addEventListener('keyup', handleChange);
}

$("#other_message").hide();
$("#service_request").on('change',function(){
    if($(this).val() == 'other'){
        $("#other_message").slideDown(500)
    }
    else {
        $("#other_message").slideUp(500)
        $("#other_message input").val('')
    }
})

$('form.needs-validation').on('submit', function (e) {
    e.preventDefault();
    if (!this.checkValidity()) {
        showError(message.opps, message.phonenumber);
        return false;
    }
    if (!checkForm) {
        showError(message.opps, message.phonenumber);
        return false;
    }
    var response = grecaptcha.getResponse();
    if (response.length == 0) {
        showRecaptchaError(message.recaptcha);
        return false;
    }
    $('#recaptcha-message').remove();
    var timerInterval;
    Swal.fire({
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
        },
        willClose: () => {
            clearInterval(timerInterval);
        }
    });
    $.ajax({
        type: 'POST',
        // url: 'https://stg-cashisof.alsoug.com/get-cashi/cp-contact-form',
        url: 'https://gw.alsoug.com/get-cashi/cp-contact-form',
        data: $(this).serialize(),
        success: function (response) {
            Swal.close(); 
            if (response.status == 1) {
                Swal.fire({
                    text: response.message,
                    icon: "success",
                    confirmButtonText: message.close,
                });
                setTimeout(() => {
                    window.location.reload(); 
                }, 2000);
            } else {
                showError(message.opps, response.message);
            }
        },
        error: function (error) {
            console.error(error);
            showError(message.opps, error.message);
        }
    });
});
function showError(title, text) {
    Swal.fire({
        icon: "error",
        title: title,
        text: text,
        confirmButtonText: message.close,
    });
}
function showRecaptchaError(text) {
    var messageElement ='';
    messageElement = $('<p>', {
        text: text,
        id: 'recaptcha-message'
    });
    $('.g-recaptcha').append(messageElement);

    messageElement[0].scrollIntoView();
    $('#recaptcha-message').css({ "font-size": "12px", "color": "#fff", "margin": "0" });
}
